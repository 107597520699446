<template>
    <div class="page-flag-2">
        <headBar
            :title="$store.state.flagJson.type ? '虎鹿瘦喜 新年FLAG' : '超鹿健康资料'"
            left-arrow
            @click-left="newAppBack"
        />
        <div class="pd">
            <img
                class="banner"
                src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/banner.png"
                alt=""
            />
            <h2 class="title f36">您的身高和体重是？</h2>
            <div class="pub_full pub_flexmiddle height_box">
                <div class="people">
                    <img
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/men.png"
                        v-if="type == 1"
                    />
                    <img
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/women.png"
                        v-else
                    />
                </div>
                <div class="rule_box">
                    <pickershurule
                        v-model="height"
                        :maxNum="250"
                        :minNum="50"
                        :ruleCount="7"
                        color="#6C727A"
                        activeColor="#232831"
                    />
                </div>
                <div class="unit">cm</div>
            </div>
            <div class="weight_box">
                <div class="weight_title">体重(kg)</div>
                <pickerhenrule
                    v-model="weight"
                    :maxNum="200"
                    :minNum="10"
                    :ruleCount="50"
                    :oneGridValue="0.1"
                    :borderSpacing="10"
                    color="#6C727A"
                    activeColor="#232831"
                />
            </div>
        </div>
        <div class="button-box fixed">
            <div class="cl-button f32 fw5 row-center-center" @click="goNext">
                下一步（ 2/7 ）
            </div>
        </div>
    </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod';
import { getList } from './index';
import headBar from '@/components/app/headBar';
import pickershurule from '@/components/app/pickershurule';
import pickerhenrule from '@/components/app/pickerhenrule';
import userMixin from '@/mixin/userMixin';
export default {
    mixins: [userMixin],
    components: {
        headBar,
        pickershurule,
        pickerhenrule,
    },
    data() {
        return {
            height: 165,
            weight: 45
        };
    },
    computed:{
        type(){
            return this.$store.state.flagJson.sex?this.$store.state.flagJson.sex[0]:0
        }
    },
    methods: {
        newAppBack,
        goNext() {
            this.$store.commit('setFlagJson',{
                height:this.height,
                weight:this.weight
            })
            this.$router.push('page-3');console.log(this.$store.state.flagJson)
            this.countPoint('43','311','1475')
        },
    },
    async created() {console.log(this.$store.state.flagJson)
        await this.$getAllInfo();

        getList(this.userId, this.token, 'height').then(value=>{
            if(value){
                this.height=~~value;
            }
        });
        // 体重
        getList(this.userId, this.token, 'weight').then(value=>{
            if(value){
                this.weight=parseFloat(value);
            }
        });
        this.countPoint('43','311','1474')
    },
};
</script>
<style lang="less" scoped>
@import url(./index.less);
.page-flag-2 {
    background-color: #f9fafb;
    min-height: 100vh;
    .banner {
        margin-bottom: 32px;
    }
    .title {
        line-height: 50px;
    }
    .height_box {
        .people {
            width: 382px;
            height: 496px;
            background: #f5f5f5;
            border-radius: 16px;
            img {
                width: 100%;
            }
        }
        .rule_box {
            height: 580px;
            flex: 1;
            display: flex;
            justify-content: flex-end;
            position: relative;
            .com_columnrule {
                font-family: BebasNeueBold;
                /deep/.num {
                    font-size: 36px;
                }
            }
        }
        .unit {
            font-weight: bold;
            color: #232831;
            margin: 0 24px 0 12px;
            font-size: 22px;
        }
    }
    .weight_box {
        margin-top: 60px;
        .cs-scrollrule {
            font-family: BebasNeueBold;
        }
        /deep/.now {
                background: linear-gradient(to right, rgba(253, 253, 253, 0) 0%, #F9FAFB 50%, rgba(253, 253, 253, 0) 100%);
            }
        .weight_title {
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }
}
</style>
