<template>
    <div class="com_columnrule">
        <div class="wheel-scroller">
            <div
                v-for="(item, index) in counter"
                :key="index"
                class="witem"
                :class="{ active: index == acindex }"
            >
                <div class="num" >{{ index + minNum }}</div>
                <div class="rule"></div>
            </div>
        </div>
    </div>
</template>

<script>
import BScroll from '@better-scroll/core';
import Wheel from '@better-scroll/wheel';
BScroll.use(Wheel);
export default {
    name: 'ColumnRule',
    props: {
        // 值
        value: {
            type: Number,
            default: 100,
        },
        // 最大刻度
        maxNum: {
            type: Number,
            default: 1000,
        },
        // 最小刻度
        minNum: {
            type: Number,
            default: 0,
        },
        // 大刻度尺个数
        ruleCount: {
            type: Number,
            default: 10,
        },
        // 字体颜色
        color: {
            type: String,
            default: 'black',
        },
        // 选中字体颜色
        activeColor: {
            type: String,
            default: 'red',
        }
    },
    data() {
        return {
            acindex: this.value - this.minNum,
        };
    },
    computed: {
        counter() {
            return this.maxNum - this.minNum + 1;
        },
    },
    mounted() {
        this.getMainValue();
    },
    watch:{
        value(){
            if (!this.scrollrule.movingDirectionY) {
                this.scrollrule.wheelTo(this.value-this.minNum)
            }
        }
    },
    methods: {
        getMainValue() {
            const dom = this.$el;
            const docStyle = dom.style;
            docStyle.setProperty('--num-color', this.color);
            docStyle.setProperty('--num-active', this.activeColor);
            docStyle.setProperty('--box-height', dom.clientHeight + 'px');
            this.oneHeight = dom.clientHeight / this.ruleCount;
            docStyle.setProperty('--item-height', this.oneHeight + 'px');
            this.init();
        },
        init() {
            setTimeout(() => {
                this.initScroll();
            }, 0);
        },
        initScroll() {
            this.scrollrule = new BScroll(this.$el, {
                wheel: {
                    selectedIndex: this.value - this.minNum,
                    wheelWrapperClass: 'wheel-scroller',
                    wheelItemClass: 'witem',
                    rotate: 0,
                },
                probeType: 3
            });
            this.scrollrule.on('scroll', (pos) => {
                this.scrollY = Math.abs(pos.y);
                const index=Math.round(this.scrollY / this.oneHeight);
                this.acindex=index;
                const NumValue = index + this.minNum;
                if(NumValue!=this.value){
                    this.$emit('input', NumValue);
                }
            });
            this.scrollrule.on('scrollEnd', () => {
                //滚动完成之后获取当前选取的索引值
                this.acindex = this.scrollrule.getSelectedIndex();
                const NumValue = this.acindex + this.minNum;
                if(NumValue!=this.value){
                    this.$emit('input', NumValue);
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
.com_columnrule {
    height: 100%;
    min-height: 100px;
    overflow: hidden;
    position: relative;
    padding-left: 2%;
    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 23%;
        background: linear-gradient(#F9FAFB,rgba(249, 250, 251, 0));
        z-index: 1;
    }
    &::after{
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 23%;
        background: linear-gradient(rgba(249, 250, 251, 0) 0%, #F9FAFB 100%);
    }
    div {
        box-sizing: border-box;
    }
    .wheel-scroller {
        margin-top: calc(var(--box-height) / 2);
    }
    .witem {
        height: var(--item-height);
        position: relative;
        display: flex;
        justify-content: space-between;
        width: calc(var(--item-height) * 1.7);

        .num {
            // position: absolute;
            top: 0;
            left: 20px;
            font-size: calc(var(--item-height) / 3);
            transition: transform 0.2s;
            will-change: transform;
            color: var(--num-color);
            font-weight: bold;
        }
        .rule {
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/rule.png) no-repeat right;
            background-size: 100% 100%;
            width: 20%;
            position: relative;
        }
        &.active {
            .num {
                color: var(--num-active);
                transform: scale3d(1.2,1.2,1.2);
            }
            .rule::before{
                content: '';
                position: absolute;
                top: 0;width: 140%;
                height: 5px;
                margin-top: -1px;
                background: var(--num-active);
                right: 0;
            }
        }
    }
}
</style>
