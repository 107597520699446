<template>
    <div class="cs-scrollrule" ref="scrollrule">
        <div class="cs-scrollrule-hook" ref="rulehook">
            <div
                class="cs-scroll-item"
                v-for="(item, index) in counter"
                :key="index"
            >
                <div class="cs-scroll-item-num">
                    {{ minNum + ~~(index * oneGridValue * borderSpacing) }}
                </div>
            </div>
        </div>
        <div class="now">{{ value }}</div>
        <!--刻度表的针-->
        <div class="cs-scroll-item-pointer"></div>
    </div>
</template>

<script>
import BScroll from '@better-scroll/core';
export default {
    name: 'hen-ruler',
    props: {
        // 值
        value: {
            type: Number,
            default: 100,
        },
        // 最大刻度
        maxNum: {
            type: Number,
            default: 1000,
        },
        // 最小刻度
        minNum: {
            type: Number,
            default: 0,
        },
        // 指针颜色
        pointerColor: {
            type: String,
            default: 'rgb(97,206,81)',
        },
        // 几个刻度尺
        ruleCount: {
            type: Number,
            default: 40,
        },
        // 字体大小
        numSize: {
            type: Number,
            default: 16,
        },
        // 每个格子多大
        oneGridValue: {
            type: Number,
            default: 1,
        },
        //几个格子一组
        borderSpacing: {
            type: Number,
            default: 10,
        },
        // 字体颜色
        color: {
            type: String,
            default: 'black',
        },
        // 选中字体颜色
        activeColor: {
            type: String,
            default: 'red',
        },
    },
    data() {
        return {};
    },
    methods: {
        // 全局初始化
        init() {
            setTimeout(() => {
                this.initScroll();
                this.setScroll();
            }, 0);
        },
        setScroll() {
            if(this.scrollrule){
                this.scrollrule.scrollTo(
                    -(
                        this.oneWidth *
                        ((this.value - this.minNum) / this.oneGridValue)
                    ),
                    0,
                    0
                );
            }
        },
        // 初始化滚动
        initScroll() {
            if (this.scrollrule) {
                this.scrollrule.refresh();
                return;
            }
            this.scrollrule = new BScroll(this.$refs.scrollrule, {
                // 实时监听滚动的位置并返回
                probeType: 3,
                scrollX: true,
                scrollY: false,
            });

            // 监听滚动开始
            this.scrollrule.on('scrollStart', (pos) => {
                this.$emit('scroll-start', true);
            });

            // 监听滚动
            this.scrollrule.on('scroll', (pos) => {
                this.scrollX = Math.abs(Math.round(pos.x));
                let index = Math.abs(Math.round(this.scrollX / this.oneWidth));
                let value = (this.minNum + index * this.oneGridValue).toFixed(
                    2
                );
                if(value!=this.value){
                    this.$emit('input', parseFloat(value));
                }
            });

            //滚动结束监听
            this.scrollrule.on('scrollEnd', (pos) => {
                //滑动后的小距离
                let littleNum = this.scrollX % this.oneWidth;
                //计算距离下一个刻度的差值
                let vul = this.oneWidth - littleNum;
                //滑动过程中的方向0 表示没有滑动
                if (this.scrollrule.movingDirectionX) {
                    if (pos.x > this.scrollrule.maxScrollX) {
                        if (vul > 0.5 && vul < this.oneWidth - 0.5) {
                            if (littleNum > this.oneWidth / 2) {
                                this.scrollrule.scrollBy(-vul, 0, 300);
                            } else {
                                this.scrollrule.scrollBy(littleNum, 0, 300);
                            }
                        }
                    }
                    // 第一次true是因为滚动结束,第二个true是因为位置微调了一次,所以又滚动了
                    this.$emit('scroll-end', true);
                }
            });
        },
        // 获取指针颜色等信息
        getMainValue() {
            const dom = this.$refs.scrollrule;
            const docStyle = dom.style;
            docStyle.setProperty('--num-color', this.color);
            docStyle.setProperty('--num-active', this.activeColor);
            docStyle.setProperty('--box-width', dom.clientWidth + 'px');
            this.oneWidth = dom.clientWidth / this.ruleCount;
            docStyle.setProperty(
                '--item-width',
                this.oneWidth * this.borderSpacing + 'px'
            );
            this.init();
            // console.log(this.$refs.rulehook.clientWidth)
        },
        // 暴露给父级,如果后期需要修改某些参数,需要重新初始化一下组件
        initRule() {
            this.getMainValue();
            // this.init()
        },
    },
    computed: {
        counter() {
            const co =
                (this.maxNum - this.minNum) /
                    this.oneGridValue /
                    this.borderSpacing +
                1;
            return co;
        },
    },
    mounted() {
        this.getMainValue();
    },
    watch: {
        value() {
            if (!this.scrollrule.movingDirectionX) {
                this.setScroll();
            }
        },
    },
};
</script>


<style scoped>
.cs-scrollrule {
    overflow: hidden;
    box-sizing: border-box;
    /* height: calc(var(--item-width) / 1.3); */
    position: relative;
}
.cs-scrollrule::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 25%;
    bottom: 0;
    background-image: linear-gradient(
        to right,
        #f9fafb,
        rgba(249, 250, 251, 0)
    );
    z-index: 1;
}
.cs-scrollrule::after {
    position: absolute;
    content: '';
    bottom: 0;
    top: 0;
    width: 25%;
    right: 0;
    background-image: linear-gradient(
        to right,
        rgba(249, 250, 251, 0),
        #f9fafb
    );
}
.cs-scrollrule div {
    box-sizing: border-box;
}

.cs-scrollrule-hook {
    list-style: none;
    border-collapse: collapse;
    white-space: nowrap;
    display: inline-block;
    padding-left: calc(var(--box-width) / 2);
    padding-right: calc(var(--box-width) / 2 - var(--item-width));
}

.cs-scroll-item {
    display: inline-block;
    width: var(--item-width);
    text-align: left;
    vertical-align: top;
    background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/rule2.png)
        no-repeat top;
    background-size: 100% 100%;
    height: calc(var(--item-width) / 4);
    position: relative;
}

/*刻度表数字*/
.cs-scroll-item-num {
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    color: var(--num-color);
    font-size: calc(var(--item-width) / 5.35);
    margin-top: calc(var(--item-width) / 7);
    font-weight: bold;
}

/*中间红色指针*/
.cs-scroll-item-pointer {
    position: absolute;
    top: 0;
    left: 50%;
    height: calc(var(--item-width) / 4 + 2px);
    margin-left: -1px;
    border-right: 5px solid var(--num-active);
}

.cs-scroll-item-rule {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}

.now {
    width: 15%;
    margin: 0 auto;
    background: #f9fafb;
    text-align: center;
    position: relative;
    z-index: 2;
    font-weight: bold;
    font-size: calc(var(--item-width) / 4);
    margin-top: calc(var(--item-width) / 11);
}

/*刻度1的*/
.cs-scale-one {
    height: calc(30 / 750 * 100vw);
}

/*刻度0.5的*/
.cs-scale-half {
    height: calc(44 / 750 * 100vw);
}

/*刻度10的*/
.cs-scale-integer {
    height: calc(60 / 750 * 100vw);
}
</style>
